import React from "react"
import Image from "~components/image/image"

import { useWebsiteConfiguration } from "~queries/hooks"

/* Import Local Style(s) */
import "./grid-gallery-item.scss"

const GridGalleryItem = ({ gallery: { name, url, image, boothID, isNest, isSpecialProject } }) => {

  const { nestColor, specialProjectsColor, boothSections } = useWebsiteConfiguration()
  const nc = (nestColor && nestColor.hex && isNest) ? nestColor.hex : 'inherit'
  const nestClass = isNest ? 'is_nest ' : ''
  const matchingSections = boothSections.filter(b => boothID !== null && boothID.includes(b.title)).map(b => b.boothColor.hex)
  const bc = matchingSections.length > 0 ? matchingSections[0] : 'black'
  const sp = (specialProjectsColor && specialProjectsColor.hex && isSpecialProject) ? specialProjectsColor.hex : 'inherit'
  const spClass = isSpecialProject ? 'is_sp' : ''
  const boothColor = isNest ? nc : isSpecialProject ? sp : bc 


  let styleColor = {color: 'inherit'}

  if(isNest){
    styleColor.color = nc
  }else if(isSpecialProject){
    styleColor.color = sp
  }


  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="grid-gallery-item"
    >
      <div className="grid-gallery-item__image-wrapper">
        {image && image.asset && (
          <div className="grid-gallery-item__image-wrapper__inner">
            <Image image={image.asset.gatsbyImageData} objectFit="cover" />
          </div>
        )}
      </div>
      <p className={"gallery_info " + nestClass + spClass} style={styleColor}>
        <span className="gallery_title">{name} {isNest && <span className="nest_wrapper">(Nest)</span>}{isSpecialProject && <span className="sp_wrapper">(SP)</span>}</span>
        <span className="booth_id" style={{color: boothColor}}>{boothID}</span>
      </p>
    </a>
  )
}

export default GridGalleryItem
