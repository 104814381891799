import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import classnames from "classnames"

import { useWebsiteConfiguration } from "~queries/hooks"

/* Import Local Style */
import "./list-gallery-item.scss"

const ListGalleryItem = ({ gallery: { name, url, boothID, isNest, isSpecialProject } }) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    if(url){
      setIsHovered(true)
    }
  }
  const handleMouseLeave = () => setIsHovered(false)

  const urlFilter = url => {

    if(!url){
      return ''
    }

    url = url.substr(-1) === "/" ? url.slice(0, -1) : url
    url = url.replace("https://", "")
    url = url.replace("http://", "")
    return url
  }

  // generate booth info
  const { nestColor, specialProjectsColor, boothSections } = useWebsiteConfiguration()
  const nc = (nestColor && nestColor.hex && isNest) ? nestColor.hex : 'inherit'
  const sp = (specialProjectsColor && specialProjectsColor.hex && isSpecialProject) ? specialProjectsColor.hex : 'inherit'
  const nestClass = isNest ? 'is_nest' : ''
  const spClass = isSpecialProject ? 'is_sp' : ''
  const matchingSections = boothSections.filter(b => boothID !== null && boothID.includes(b.title)).map(b => b.boothColor.hex)
  const bc = matchingSections.length > 0 ? matchingSections[0] : 'black'
  const boothColor = isNest ? nc : isSpecialProject ? sp : bc 

  let styleColor = {color: 'inherit'}

  if(isNest){
    styleColor.color = nc
  }else if(isSpecialProject){
    styleColor.color = sp
  }


  return (
    <div
      className={classnames("list-gallery-item font-size--small")}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <CSSTransition in={!isHovered} classNames="fade" timeout={300}>
        <div className="gallery--name-wrapper">
          <a href={url} className={"name " + nestClass + spClass} target="_blank" rel="noreferrer" style={styleColor}>
            <span className="gallery_title">{name} {isNest && <span className="nest_wrapper">(Nest)</span>} {isSpecialProject && <span className="sp_wrapper">(Special Project)</span>}</span>
            <span className="booth_id" style={{color: boothColor}}>{boothID}</span>
          </a>
        </div>
      </CSSTransition>
      <CSSTransition
        in={isHovered}
        classNames="fade"
        timeout={300}
        unmountOnExit
      >
        <a href={url} className="url" target="_blank" rel="noreferrer">
          { url && <span>{urlFilter(url)}</span> }
          <span className="booth_id" style={{color: boothColor}}>{boothID}</span>
        </a>
      </CSSTransition>
    </div>
  )
}
export default ListGalleryItem
