import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import classnames from "classnames"

/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Local Components*/
import ListGalleryItem from "./components/listGalleryItem/listGalleryItem"
import GridGallery from "./components/gridGalleryItem/gridGalleryItem"

/* Import Local Style */
import "./galleries.scss"

const Galleries = ({ galleries = {}, isGrid = false, isPast = false }) => {
  return (
    <AnimatePresence exitBeforeEnter>
      {!isGrid && (
        <motion.ul
          className={classnames("galleries galleries-list", {past_galleries: isPast})}
          key="grid"
          initial="initial"
          animate="animate"
          exit="exit"
          variants={elementTransitionAnimation.variants}
        >
          <AnimatePresence initial={false} exitBeforeEnter>
            {Object.keys(galleries).map(galleryGroup => {
              const sortedGalleriesGroup = galleries[galleryGroup]
              if (sortedGalleriesGroup.length === 0) {
                return null
              }
              return (
                <motion.li
                  className="galleries__gallery-group"
                  key={galleryGroup}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={elementTransitionAnimation.variants}
                >
                  <div className="galleries__gallery-group__title font-size--small">
                    {galleryGroup.toUpperCase()}
                  </div>
                  <motion.ul>
                    <AnimatePresence initial={false} exitBeforeEnter>
                      {sortedGalleriesGroup.map(gallery => (
                        <motion.li
                          key={gallery._id}
                          initial="initial"
                          animate="animate"
                          exit="exit"
                          variants={elementTransitionAnimation.variants}
                        >
                          <ListGalleryItem
                            gallery={gallery}
                            key={gallery._id}
                          />
                        </motion.li>
                      ))}
                    </AnimatePresence>
                  </motion.ul>
                </motion.li>
              )
            })}
          </AnimatePresence>
        </motion.ul>
      )}
      {isGrid && (
        <motion.ul
          className={classnames("galleries galleries-grid", {past_galleries: isPast})}
          key="list"
          initial="initial"
          animate="animate"
          exit="exit"
          variants={elementTransitionAnimation.variants}
        >
          <AnimatePresence initial={false} exitBeforeEnter>
            {Object.keys(galleries).map(galleryGroup => {
              const sortedGalleriesGroup = galleries[galleryGroup]
              if (sortedGalleriesGroup.length === 0) {
                return null
              }
              return (
                <motion.li
                  className="galleries__gallery-group"
                  key={galleryGroup}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={elementTransitionAnimation.variants}
                >
                  <div className="galleries__gallery-group__title font-size--small">
                    {galleryGroup.toUpperCase()}
                  </div>

                  <motion.ul>
                    <AnimatePresence initial={false} exitBeforeEnter>
                      {sortedGalleriesGroup.map(gallery => (
                        <motion.li
                          key={gallery._id}
                          initial="initial"
                          animate="animate"
                          exit="exit"
                          variants={elementTransitionAnimation.variants}
                        >
                          <GridGallery gallery={gallery} />
                        </motion.li>
                      ))}
                    </AnimatePresence>
                  </motion.ul>
                </motion.li>
              )
            })}
          </AnimatePresence>
        </motion.ul>
      )}
    </AnimatePresence>
  )
}
export default Galleries
